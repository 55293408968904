var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.mode && _vm.mode === 1)?_c('div',[_c('div',{staticClass:"field-label"},[_vm._v(" "+_vm._s(_vm.placeholder)+" ")]),_c('v-textarea',{key:"textarea",attrs:{"value":_vm.value,"placeholder":_vm.placeholder,"filled":"","single-line":"","color":"fields","no-resize":"","error-messages":_vm.errorMessage || _vm.errors.value},on:{"input":(newValue) => {
          _vm.onChange(newValue), _vm.$v.$touch();
        },"blur":function($event){return _vm.$v.$touch()}}})],1):_c('div',[_c('div',{staticClass:"field-label"},[_vm._v(" "+_vm._s(_vm.placeholder)+" ")]),_c('v-text-field',{key:"input",attrs:{"value":_vm.value,"type":_vm.inputType,"min":_vm.min,"max":_vm.max,"emailType":_vm.emailField,"phoneType":_vm.phoneField,"filled":"","color":"fields","error-messages":_vm.errorMessage || this.fieldError || _vm.errors.value},on:{"input":(newValue) => {
          _vm.onChange(newValue), _vm.$v.$touch();
        },"blur":function($event){return _vm.$v.$touch()},"keypress":(keyPress) => {
          // Some browsers allow typing characters in number field, this will prevent that.
          if (
            _vm.inputType === 'number' &&
            (keyPress.keyCode < 48 || keyPress.keyCode > 57)
          ) {
            keyPress.preventDefault();
          }
        }}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }