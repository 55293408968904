<template>
  <div>
    <div v-if="mode && mode === 1">
      <div class="field-label">
        {{ placeholder }}
      </div>
      <v-textarea
        key="textarea"
        :value="value"
        :placeholder="placeholder"
        filled
        single-line
        color="fields"
        no-resize
        :error-messages="errorMessage || errors.value"
        @input="
          (newValue) => {
            onChange(newValue), $v.$touch();
          }
        "
        @blur="$v.$touch()"
      />
    </div>
    <div v-else>
      <div class="field-label">
        {{ placeholder }}
      </div>
      <v-text-field
        key="input"
        :value="value"
        :type="inputType"
        :min="min"
        :max="max"
        :emailType="emailField"
        :phoneType="phoneField"
        filled
        color="fields"
        :error-messages="errorMessage || this.fieldError || errors.value"
        @input="
          (newValue) => {
            onChange(newValue), $v.$touch();
          }
        "
        @blur="$v.$touch()"
        @keypress="
          (keyPress) => {
            // Some browsers allow typing characters in number field, this will prevent that.
            if (
              inputType === 'number' &&
              (keyPress.keyCode < 48 || keyPress.keyCode > 57)
            ) {
              keyPress.preventDefault();
            }
          }
        "
      />
    </div>
  </div>
</template>

<script>
import abstractField from "@/utils/AbstractField";
import validationThings from "@/mixins/validation-things";

export default {
  name: "InputField",
  mixins: [abstractField, validationThings],
  props: {
    min: Number,
    max: Number,
    typeCode: String,
    mode: { type: Number, default: 0 },
    paramValue: String,
    attribute: Object,
  },
  data() {
    return {
      emailField:
        this.attribute && this.attribute.fieldType === "mail" ? "email" : null,
      phoneField:
        this.attribute && this.attribute.fieldType === "phoneNumber"
          ? "phoneNumber"
          : null,
      fieldError: null,
    };
  },
  watch: {
    paramValue(newParamValue, oldParamValue) {
      if (newParamValue !== oldParamValue) {
        this.value = newParamValue;
      }
      if (this.paramValue) {
        if (this.emailField) {
          if (
            /^(?:(?!.*?[.]{2})[a-zA-Z0-9_](?:[a-zA-Z0-9._+!%-]{1,64}|)|"[a-zA-Z0-9._+!% -]{1,64}")@[a-zA-Z0-9][a-zA-Z0-9.-]+(\.[a-z]{2,}|.[0-9]{1,})$/.test(
              this.value
            )
          ) {
            this.fieldError = "";
          } else {
            this.fieldError = this.$t("rules.email");
          }
        } else if (this.phoneField) {
          if (
            /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{2,})$/.test(
              this.value
            )
          ) {
            this.fieldError = "";
          } else {
            this.fieldError = this.$t("rules.mobilePhone");
          }
        }
      } else {
        this.fieldError = "";
      }
    },
  },
  methods: {
    onChange(newValue) {
      this.$emit("onChange", {
        id: this.id,
        value: newValue,
      });
    },
  },
  mounted() {
    if (this.paramValue !== undefined) this.value = this.paramValue;
  },
  computed: {
    inputType() {
      return this.typeCode.toLowerCase().indexOf("string") > -1
        ? "text"
        : "number";
    },
  },
  validations: {
    value: {
      required: (value, vm) => {
        return vm.required ? !!value : true;
      },
      minValue: (value, vm) => {
        return (!!vm.min || Number.isInteger(vm.min)) &&
          vm.inputType === "number"
          ? (vm.required ? value : value || vm.min) >= vm.min
          : true;
      },
      maxValue: (value, vm) => {
        return (!!vm.max || Number.isInteger(vm.max)) &&
          vm.inputType === "number"
          ? (vm.required ? value : value || vm.max) <= vm.max
          : true;
      },
      minLength: (value, vm) => {
        return (!!vm.min || Number.isInteger(vm.min)) && vm.inputType === "text"
          ? (vm.required ? value || "" : value || "_".repeat(vm.min)).length >=
              vm.min
          : true;
      },
      maxLength: (value, vm) => {
        return (!!vm.max || Number.isInteger(vm.max)) && vm.inputType === "text"
          ? (vm.required ? value || "" : value || "_".repeat(vm.max)).length <=
              vm.max
          : true;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep.v-input__slot:before {
  border-color: #fbfbfb !important;
}

.field-label {
  font-size: 15px;
}
</style>
